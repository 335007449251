/* eslint-disable max-lines */
import { AfterViewChecked, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ClientSelectors } from '@app/core/state/clients/clients.selectors';
import { ClientTypesSelectors } from '@app/core/state/clientTypes/clientTypes.selectors';
import { Store } from '@ngrx/store';
import { isValidCorporateIdentity } from '@bl/functions';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { delay, filter, first, map, mergeMap, switchMap, take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, EMPTY, Observable, of, Subject } from 'rxjs';
import { Client } from 'src/app/core/entity/client';
import { FinancialYear } from 'src/app/core/entity/financialyear';
import { Role, User } from 'src/app/core/entity/user';
import { UserAuthFacade } from 'src/app/core/facades/user-auth.facade';
import { ClientService } from 'src/app/core/services/clients.service';
import { CloudCompanyService } from 'src/app/core/services/cloud-company.service';
import { DataSafeService } from 'src/app/core/services/datasafe.service';
import { UserService } from 'src/app/core/services/user.service';
import { ClientActions } from 'src/app/core/state/clients/clients.actions';
import { ToastActions } from 'src/app/core/state/toast/toast.actions';
import { ClientTypeType, FirmType, TeamType, UserType } from 'src/app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { UserSelectors } from 'src/app/core/state/users/users.selectors';
import { DirtyItem } from 'src/app/shared/misc/dirty.item';
import { TabsComponent } from '../tabs/tabs.component';
import { openDialog } from '@app/shared/misc/openDialog';
import { TeamsSelectors } from '@app/core/state/teams/teams.selectors';
import { TeamsActions } from '@app/core/state/teams/teams.actions';
import { Router } from '@angular/router';
import { ActivateSustainabilityReportingDialog } from './components/activate-sustainability-reporting-dialog/activate-sustainability-reporting-dialog';
import { toClientTransformer, toClientTypeTransformer } from '@app/core/state/transformers/transformers';
import { AuthSelectors } from '@app/core/state/auth/auth.selectors';
import { isAdmin } from '@app/core/misc/isAdmin';
import { getActivateOrDeactivateSustainabilityReportingInfo } from '@app/shared/misc/getActivateOrDeactivateSustainabilityReportingInfo';
import { MissingCloudApiKeyDialog } from '../missing-cloud-api-key/missing-cloud-api-key.dialog';
import { CreditInformationActions } from '@app/core/state/credit-information/credit-information.actions';
import { CreditInformationSelectors } from '@app/core/state/credit-information/credit-information.selectors';
import { CreditInformationType } from '@app/core/state/types/credit-information.types';
import {
  getAccountPeriod,
  toClientTypeFromCreditInformationTransformer,
} from '@app/core/state/transformers/credit-information.transformers';
import { CreditInformationUpdateDialog } from './dialogs/credit-information-update.dialog';
import { AllInclusiveService } from '@app/core/services/all-inclusive.service';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { CreateCloudDbWizardService } from '@app/shared/components/cloud-db-export-wizard/create-clouddb-wizard.service';
import { AdvisoryToolActivateClientDialog } from '@app/advisory-tool/components/advisory-tool-activate-client/advisory-tool-activate-client.dialog';
import { CreateClouddbConfirmationDialog } from './dialogs/create-clouddb-confirmation.dialog';
import { GenerateAdvisoryReportDialog } from './dialogs/generate-advisory-report.dialog';
import { ChangeResponsibleWarningDialog } from './dialogs/change-responsible-warning.dialog';
import { CorporateIdentityExistsDialog } from './dialogs/corporate-identify-exists.dialog';
import { SaveDirtyChangesDialog } from './dialogs/save-dirty-changes.dialog';
import { CorporateIdentityInputComponent } from '../corporate-identity-input/corporate-identity-input.component';
import { TooltipOptions } from 'primeng/api';
import { DialogResult } from '../form-dialog-base/form-dialog-base.component';
import { AskForCollaborationPackagesOnNewClientDialog } from './dialogs/ask-for-collaboration-packages-on-new-client.dialog';
import { FirmSelectors } from '@app/core/state/firm/firm.selectors';
import { isSweden } from '@app/core/entity/locale';

export interface NewClientDialogConfig {
  tabToOpen: string;
  client: Client;
  firm: FirmType;
  newClientEmitter?: (client: Client) => void;
  updateEmitter?: (updated: boolean) => void;
}

export interface NewClientDialogResult {
  client?: Client;
}

@Component({
  selector: 'app-new-client-dialog',
  templateUrl: './new.client.dialog.html',
  providers: [DataSafeService, DialogService, CreateCloudDbWizardService],
})
export class NewClientDialog implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('tabs', { static: true }) tabs: TabsComponent;
  @ViewChild('corporateIdentityInput', { static: true }) corporateIdentityInput: CorporateIdentityInputComponent;

  public availableUsers$: Observable<UserType[]>;
  public selectedType: string;
  public displayDialog = false;
  public displayInviteBlapp = false;
  public hidePleaseWait = true;
  public documentTabActive = false;
  public cloudServiceTabActive = false;
  public amlTabActive = false;
  public hasPermissionToChange$: Observable<{ value: boolean }>;
  public disabledTooltip = 'Bara nuvarande klientansvarig eller byråadministratörer kan ändra klientansvarig.';
  public clientTypes$: Observable<ClientTypeType[]>;
  public selectedTeamIds: number[];
  public canActivateSustainabilityReportingDialog = false;
  public createCloudDbTooltipOptions: TooltipOptions = {
    tooltipPosition: 'top',
    tooltipStyleClass: 'tooltip-extended-width',
  };
  public createCloudDbButtonText$: Observable<string>;

  private listOfDirtyComponents: DirtyItem[] = [];
  private onDestroySubject: Subject<boolean>;
  private clientTypesBS: BehaviorSubject<ClientTypeType[]>;
  private teamIds: number[];
  private showMissingCollaborationInfoDialog = false;

  _model: Client = new Client('');
  submitted = false;
  creditInformationDefaultFinancialYears: FinancialYear;
  rootTeam: TeamType;
  teams: { id: number; name: string }[];

  constructor(
    private clientService: ClientService,
    private cloudCompanyService: CloudCompanyService,
    private userAuthFacade: UserAuthFacade,
    private userService: UserService,
    private store: Store<AppState>,
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig<NewClientDialogConfig>,
    private dialogService: DialogService,
    private router: Router,
    private allInclusiveService: AllInclusiveService,
    private createCloudDbWizardService: CreateCloudDbWizardService,
  ) {
    this.onDestroySubject = new Subject();
    this.availableUsers$ = this.store.select(UserSelectors.activeUsersWithChoose);
    this.hasPermissionToChange$ = this.userAuthFacade.isAdminOrResponsibleForClient$.pipe(map((value) => ({ value })));
    this.clientTypesBS = new BehaviorSubject([]);
    this.store
      .select(ClientTypesSelectors.all)
      .pipe(
        map((clientTypes) => [{ name: '', description: '- Välj -' }].concat(clientTypes)),
        takeUntil(this.onDestroySubject),
      )
      .subscribe(this.clientTypesBS);
    this.clientTypes$ = this.clientTypesBS.asObservable();
    this.store
      .select(this.selector)
      .pipe(first())
      .subscribe(({ ids }) => {
        this.teamIds = ids;
      });
    this.store
      .select(TeamsSelectors.selectRootTeam)
      .pipe(
        filter((rootTeam) => Boolean(rootTeam)),
        first(),
      )
      .subscribe((team) => {
        this.rootTeam = team;
      });

    this.teams = [];
    this.reactToCreditInformationUpdate();
    this.createCloudDbButtonText$ = this.createCloudDbButtonTextObservable();
  }

  get isNewClient() {
    return !this._model.id || this._model.id === -1;
  }

  get createCloudDbTooltip() {
    if (this.allInclusiveService.isLoggedInWithBLTAService) {
      return {
        title: 'Nytt samarbetspaket',
        description: 'En ny företagsdatabas skapas i BL Administration för samarbete med kunden',
      };
    }
    return null;
  }

  get isPossibleToCreateCloudDb() {
    const isClientPersonType = this._model && this._model.type && this._model.type.name === 'PERSON';
    const isFormValuesValid =
      this._model.corporateIdentity && !this._model.archived && this._model.name && this.selectedType;
    const isLoggedInWithAllInclusiveService = this.allInclusiveService.isLoggedInWithAnyAllInclusiveService;

    return (
      !this.isNewClient &&
      !this._model.cloudApiKey &&
      !isClientPersonType &&
      isFormValuesValid &&
      isLoggedInWithAllInclusiveService &&
      this.hidePleaseWait
    );
  }

  get isPossibleToActivateAdvisoryTool() {
    return !this.isNewClient && !this._model.advisoryTool && this.hidePleaseWait && !this._model.archived;
  }

  get isPossibleToUpdateCloudCompany() {
    return !this._model.archived && !this.isNewClient && this._model.cloudApiKey && this._model.corporateIdentity;
  }

  get activateOrDeactivateSustainabilityReportingInfo() {
    return getActivateOrDeactivateSustainabilityReportingInfo(
      this.store.select(AuthSelectors.selectAuthUser),
      toClientTypeTransformer.transform(this._model),
      this.config.data.firm,
    );
  }

  get isCurrentUserClientResponsibleOrAdmin() {
    return this.store.select(AuthSelectors.selectAuthUser).pipe(
      filter((user) => Boolean(user) && Boolean(this._model)),
      map((user) => isAdmin(user) || user.id === this._model?.responsible?.id),
    );
  }

  private setClient(model: Client, tabToOpen?: string) {
    this.showMissingCollaborationInfoDialog = false;
    if (!model.id || model.id === -1) {
      this.initNewClient();
      return;
    }

    this.initExistingClient(model, tabToOpen);
  }

  private initNewClient = () => {
    this._model = new Client('');
    this._model.userId = 0;
    this.tabs.selectFirst();
    this.userAuthFacade.updateAccessRightBasedOnResponsibleUserId(this._model.userId);
  };

  private initExistingClient = (client: Client, tabToOpen?: string) => {
    this._model = client;
    if (this._model.userId == null) {
      this._model.userId = 0;
    }
    this.selectedType = this._model.type.name;
    this.userAuthFacade.updateAccessRightBasedOnResponsibleUserId(this._model.userId);

    if (tabToOpen) {
      this.tabs.selectTabByTitle(tabToOpen);
    }
  };

  ngOnInit() {
    this.setClient(this.config.data.client, this.config.data.tabToOpen);
  }

  ngOnDestroy() {
    this.onDestroySubject.next(true);
  }

  ngAfterViewChecked(): void {
    this.corporateIdentityInput.focus();
  }

  closeDialog(client?: Client) {
    if (!this.hasDirtyItems()) {
      this.listOfDirtyComponents = [];
      this.hidePleaseWait = true;
      this.creditInformationDefaultFinancialYears = undefined;
      this.displayDialog = false;

      this.beforeCloseDialog().subscribe((result: { confirmed: boolean }) => {
        this.showMissingCollaborationInfoDialog = false;

        if (result?.confirmed) {
          this.onCreateCloudDbClick();
        }

        const closeResult: NewClientDialogResult = { client };
        this.ref.close(closeResult);
      });
    }
  }

  private beforeCloseDialog(): Observable<DialogResult> {
    if (
      this.showMissingCollaborationInfoDialog &&
      this.isPossibleToCreateCloudDb &&
      this.allInclusiveService.isLoggedInWithBLTAService
    ) {
      return openDialog(this.dialogService, AskForCollaborationPackagesOnNewClientDialog, {}).pipe(first());
    }
    return of(null);
  }

  private setType() {
    this._model.type = this.clientTypesBS.value.find((t) => t.name === this.selectedType);
  }

  onSubmit() {
    this.submitted = true;
  }

  public addClient(callback?: () => void) {
    this.onPreClientSave();

    this.store
      .select(ClientSelectors.checkUniqueOrgNr(this._model.corporateIdentity))
      .pipe(
        filter((isUnique) => isUnique || !this.isNewClient),
        mergeMap(() => this.clientService.addClient(this._model, this.teamIds)),
        first(),
      )
      .subscribe({
        next: (client) => {
          this.showMissingCollaborationInfoDialog = this.isNewClient;
          this.onAfterClientSave(client, 'Klienten har sparats');
          if (callback) {
            callback();
          }
        },
        complete: () => {
          this.hidePleaseWait = true;
        },
      });

    this.store
      .select(ClientSelectors.checkUniqueOrgNr(this._model.corporateIdentity))
      .pipe(
        filter((isUnique) => !isUnique && this.isNewClient),
        first(),
        mergeMap(() => this.showCorporateIdentityExistsDialog()),
      )
      .subscribe({
        next: (client) => {
          this.showMissingCollaborationInfoDialog = this.isNewClient;
          this.onAfterClientSave(client, 'Klienten har sparats');
        },
        complete: () => {
          this.hidePleaseWait = true;
        },
      });
  }

  public saveClientAndUpdateCloud() {
    this.onPreClientSave();

    this.clientService
      .addClient(this._model, this.teamIds)
      .pipe(
        switchMap((client: Client) =>
          this.cloudCompanyService.updateCloudCompanyInformationByClientId(client.id).pipe(map(() => client)),
        ),
      )
      .subscribe({
        next: (client: Client) => {
          this.onAfterClientSave(client, 'Klienten har sparats och uppdaterats i molnföretaget');
        },
        error: () => {
          this.hidePleaseWait = true;
        },
      });
  }

  private hasDirtyItems(): boolean {
    const isDirty = this.listOfDirtyComponents.some((item) => item.dirty);

    if (isDirty) {
      openDialog(this.dialogService, SaveDirtyChangesDialog, {}).subscribe((result) => {
        this.saveDirtyBeforeClose(result?.confirmed);
      });
    }
    return isDirty;
  }

  saveDirtyBeforeClose(save: boolean) {
    if (!save) {
      this.listOfDirtyComponents = [];
      this.closeDialog();
      return;
    }

    this.addClient(() => {
      this.closeDialog();
    });
  }

  isDirty(dirty: DirtyItem) {
    if (!this.listOfDirtyComponents.filter((item) => item.component === dirty.component).length) {
      this.listOfDirtyComponents.push(dirty);
    }
    this.listOfDirtyComponents
      .filter((item) => item.component === dirty.component)
      .forEach((item) => {
        item.dirty = dirty.dirty;
      });
  }

  imDirty() {
    this.isDirty(new DirtyItem('BasicDetails', true));
  }

  handleReturnEvent(client: Client) {
    this.hidePleaseWait = true;
    this._model.id = client.id;
    this.config.data.newClientEmitter?.(client);
    if (this._model.userId == null) {
      this._model.userId = 0;
    }
  }

  harvestDetails(client: Client) {
    if (!client.saveFlag) {
      return;
    }

    if (client.saveFlag >= 0) {
      this._model.description = client.description;
      this._model.businessArea = client.businessArea;
      this._model.specialRules = client.specialRules;
      this._model.otherInfo = client.otherInfo;
      this._model.accountant = client.accountant;
      this._model.bank = client.bank;
      this._model.registrationDate = client.registrationDate;
    }

    if (client.saveFlag === 1 && this._model.name !== '') {
      this.addClient();
    } else if (client.saveFlag === -1) {
      this.closeDialog();
    }
  }

  callForUpdate(_event: boolean) {
    this.config.data.updateEmitter?.(true);
  }

  onFinancialYearUpdated(financialYears: FinancialYear[]) {
    this._model.financialYears = financialYears;
    this.config.data.updateEmitter?.(true);
  }

  openActivateAdvisoryComponent() {
    openDialog(this.dialogService, AdvisoryToolActivateClientDialog, {
      client: toClientTypeTransformer.transform(this._model),
    }).subscribe((result) => {
      if (!result) {
        return;
      }

      const { client, openClientCard } = result;

      if (openClientCard) {
        this.openCloudServiceTab();
        return;
      }

      if (client) {
        this._model = toClientTransformer.transform(result.client);
      }
    });
  }

  onClientUpdatedInCloudServiceTab(updatedClient: Client, saveClient = true) {
    this._model.cloudApiKey = updatedClient.cloudApiKey;

    if (saveClient) {
      this.addClient();
      return;
    }

    this._model = updatedClient;
    this.handleReturnEvent(updatedClient);
  }

  openCloudServiceTab() {
    // hide the dialog and change tab to Molntjänster. Maybe continue to build on this to automatically continue with the activation
    // after cloudDb has been added
    this.tabs.selectTab(this.tabs.tabs.find((tab) => tab.tabTitle === 'Molntjänster'));
  }

  getCreditInformation() {
    this.store.dispatch(CreditInformationActions.load({ corporateIdentity: this._model.corporateIdentity }));
  }

  showCreditInformationToUpdateDialog(creditInformation: CreditInformationType) {
    openDialog(this.dialogService, CreditInformationUpdateDialog, {
      client: {
        ...toClientTypeFromCreditInformationTransformer.transform(creditInformation),
        corporateIdentity: this._model.corporateIdentity,
      },
    })
      .pipe(filter((result) => Boolean(result?.client)))
      .subscribe(({ client }) => {
        this._model = Object.assign(this._model, client);
        this.imDirty();
      });
  }

  onCreateCloudDbClick() {
    const { email } = this._model;
    openDialog(this.dialogService, CreateClouddbConfirmationDialog, { email }).subscribe((result) => {
      this.showMissingCollaborationInfoDialog = false;

      if (!result?.confirmed) {
        return;
      }

      // clients email not empty, continue with creating cloud db without updating email
      if (this._model.email) {
        this.confirmCreateCloudDbConfirmation();
        return;
      }

      // Update email on client before continuing
      this._model.email = result.email;
      this.addClient(() => this.confirmCreateCloudDbConfirmation());
    });
  }

  confirmCreateCloudDbConfirmation() {
    const client = { ...this._model };

    if (this.allInclusiveService.isLoggedInWithBLTAService) {
      this.showExportWizardForOnboarding(client);
      this.closeDialog();
      return;
    }

    this.showExportWizardForCloudDb(client);
  }

  private showExportWizardForOnboarding(client: Client): void {
    this.createCloudDbWizardService.runOnboardingWizard(client).subscribe();
  }

  private showExportWizardForCloudDb(client: Client): void {
    this.hidePleaseWait = false;

    this.createCloudDbWizardService.runCreateCloudDbWizard(client).subscribe({
      next: (cloudApiKey) => {
        this._model.cloudApiKey = cloudApiKey;
      },
      error: () => {
        this.hidePleaseWait = true;
      },
      complete: () => {
        this.hidePleaseWait = true;
      },
    });
  }

  onResponsibleUserChanged(responsibleUserId: number) {
    this.imDirty();

    if (responsibleUserId === 0) {
      return;
    }

    this.userService
      .getCurrentUser()
      .pipe(take(1))
      .subscribe((currentUser: User) => {
        if (currentUser.role === Role.FIRM_USER && currentUser.id !== responsibleUserId) {
          this.showWarningForChangingResponsibleUser();
        }
      });
  }

  onForeignCorporateIdentityChange({ checked }: CheckboxChangeEvent) {
    this._model.foreignCorporateIdentity = checked;
    this.imDirty();
  }

  onCorporateIdentyChange(corporateIdentity: string) {
    if (corporateIdentity !== '') {
      this.imDirty();
    }
  }

  public onTeamsChanged(teamIds: number[]) {
    this.teamIds = teamIds;
  }

  public OnTeamsInit() {
    if (!this.isNewClient) {
      this.updateTeams(this._model, this.rootTeam.id);
      return;
    }

    // new clients need to set their selected teams after the team selector has been initialized
    this.store
      .select(this.selector)
      .pipe(first(), delay(0)) // delay is needed to avoid ExpressionChangedAfterItHasBeenCheckedError
      .subscribe(({ ids }) => {
        this.selectedTeamIds = ids;
      });
  }

  public get selector() {
    return TeamsSelectors.selectNewClientTeamSelector;
  }

  public showActivateSustainabilityReportingDialog = () => {
    if (!this._model.cloudApiKey) {
      openDialog(this.dialogService, MissingCloudApiKeyDialog, {
        client: toClientTypeTransformer.transform(this._model),
        featureToActivate: 'hållbarhetsredovisning',
      })
        .pipe(filter((result) => Boolean(result?.client)))
        .subscribe(() => this.openCloudServiceTab());
      return;
    }

    openDialog(this.dialogService, ActivateSustainabilityReportingDialog, {
      client: toClientTypeTransformer.transform(this._model),
    })
      .pipe(filter((result) => Boolean(result)))
      .subscribe(({ client }) => {
        this._model = toClientTransformer.transform(client);
      });
  };

  public gotoSustainabilityReporting = () => {
    this.saveDirtyBeforeClose(false);
    this.router.navigateByUrl('/sustainability');
  };

  openGenerateAdvisoryReport() {
    openDialog(this.dialogService, GenerateAdvisoryReportDialog, {
      client: toClientTypeTransformer.transform(this._model),
    });
  }

  private showWarningForChangingResponsibleUser() {
    openDialog(this.dialogService, ChangeResponsibleWarningDialog, {});
  }

  private onPreClientSave() {
    this.hidePleaseWait = false;
    this.setType();
    this.setDashedCorporateIdentity();
    if (this._model.id === -1) {
      this._model.id = null;
    }
    if (this._model.userId === 0) {
      this._model.userId = null;
    }
  }

  private onAfterClientSave(client: Client, message: string) {
    if (this.isNewClient || this.hasTeamsChanged(client)) {
      this.store.dispatch(TeamsActions.loadAllTeams()); // need to reload teams so the edit team page include changes to this client
    }
    this._model = client;
    this.handleReturnEvent(client);
    this.store.dispatch(ToastActions.showInfoMessage({ summary: 'Sparat', detail: message }));
    this.isDirty(new DirtyItem('BasicDetails', false));
    this.isDirty(new DirtyItem('BusinessDetails', false));
    this.userAuthFacade.updateAccessRightBasedOnResponsibleUserId(this._model.userId);
    this.hidePleaseWait = true;
    this.store.dispatch(ClientActions.loadAllClients());
    this.updateTeams(client, this.rootTeam.id);
  }

  private setDashedCorporateIdentity() {
    if (!this._model?.corporateIdentity) {
      return;
    }

    if (this._model.corporateIdentity.length !== 10 || !isValidCorporateIdentity(this._model.corporateIdentity)) {
      return;
    }

    const dashPosition = 6;
    const years = this._model.corporateIdentity.slice(0, dashPosition);
    const checkSum = this._model.corporateIdentity.slice(dashPosition);
    this._model.corporateIdentity = `${years}-${checkSum}`;
  }

  private updateTeams = (client: Client, rootTeamId: number) => {
    // need to delay the update to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.teams = client?.teams?.filter((t) => t.id !== rootTeamId);
      this.selectedTeamIds = client?.teams?.map((t) => t.id);
    }, 0);
  };

  private hasTeamsChanged = (client: Client) => {
    const clientIds = client?.teams?.map((t) => t.id) ?? [];
    const modelIds = this._model?.teams?.map((t) => t.id) ?? [];

    return clientIds.length !== modelIds.length || !clientIds.every((id) => modelIds.includes(id));
  };

  private reactToCreditInformationUpdate = () => {
    this.store
      .select(CreditInformationSelectors.selectCreditInformation)
      .pipe(
        filter((creditInformation) => Boolean(creditInformation)),
        takeUntil(this.onDestroySubject),
      )
      .subscribe((creditInformation) => {
        if (this.isNewClient) {
          this.applyCreditInformationToModel(creditInformation);
          return;
        }

        this.showCreditInformationToUpdateDialog(creditInformation);
      });
  };

  private applyCreditInformationToModel(creditInformation: CreditInformationType): void {
    this._model = Object.assign(this._model, toClientTypeFromCreditInformationTransformer.transform(creditInformation));

    if (this._model.type && this._model.type.name) {
      this.selectedType = this._model.type.name; // update type-select element
    }

    // get default accounting period
    const accountPeriod = getAccountPeriod(creditInformation);
    if (!accountPeriod) {
      return;
    }

    this.creditInformationDefaultFinancialYears = new FinancialYear();
    this.creditInformationDefaultFinancialYears.span = accountPeriod;
  }

  private showCorporateIdentityExistsDialog() {
    return openDialog(this.dialogService, CorporateIdentityExistsDialog, {}).pipe(
      mergeMap((result) => {
        if (!result?.confirmed) {
          return EMPTY;
        }

        this.onPreClientSave();

        return this.clientService.addClient(this._model, this.teamIds).pipe(take(1));
      }),
    );
  }

  private getFirmCountryCodeObservable() {
    return this.store.select(FirmSelectors.selectCountryCode);
  }

  private createCloudDbButtonTextObservable() {
    return this.getFirmCountryCodeObservable().pipe(
      map((countryCode) => {
        if (!this.allInclusiveService.isLoggedInWithBLTAService) {
          return 'Skapa ny företagsdatabas';
        }

        if (isSweden(countryCode)) {
          return 'Skapa samarbetspaket i BL App och BLA';
        }
        return 'Skapa samarbetspaket i BL App';
      }),
    );
  }
}
