import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BystSharedModule } from '../shared/byst-shared.module';
import { BulkHandlingModule } from './bulk-handling/bulk-handling.module';
import { FirmAssignmentSettingsComponent } from './firm-assignment-settings/firm-assignment-settings.component';
import { FirmTaskTemplateAskArchiveDialog } from './firm-task-templates/firm-task-template-ask-archive.dialog';
import { FirmTaskTemplateDetailsComponent } from './firm-task-templates/firm-task-template-details.component';
import { FirmTaskTemplatesComponent } from './firm-task-templates/firm-task-templates.component';
import { FirmUsersComponent } from './firm-users/firm-users.component';
import { FirmUsersConfirmDialogComponent } from './firm-users/firm-users.confirm.component';
import { FirmDataComponent } from './firm.data/firm.data.component';
import { FileImportComponent } from './import/file.import.component';
import { FirmResetComponent } from './firm-reset/firm-reset.component';
import { TeamsComponent } from './teams/teams.component';
import { DueComplianceActivationBoxComponent } from '@app/shared/components/due-compliance-activation-box/due-compliance-activation-box.component';
import { FirmSystemSettingsComponent } from './firm-system-settings/firm-system-settings.component';
import { FirmSettingsDialog } from './firm.settings.dialog';
import { HideIfCountriesDirective } from '@app/shared/directives/hide-if-countries.directive';

@NgModule({
  declarations: [
    FileImportComponent,
    FirmDataComponent,
    FirmAssignmentSettingsComponent,
    FirmUsersConfirmDialogComponent,
    FirmUsersComponent,
    FirmSystemSettingsComponent,
    FirmSettingsDialog,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BystSharedModule,
    BulkHandlingModule,
    FirmTaskTemplatesComponent,
    FirmTaskTemplateDetailsComponent,
    FirmTaskTemplateAskArchiveDialog,
    FirmResetComponent,
    TeamsComponent,
    DueComplianceActivationBoxComponent,
    HideIfCountriesDirective
  ],
  exports: [FirmSettingsDialog],
})
export class FirmSettingsModule { }
