import { createReducer, on } from '@ngrx/store';
import { UserType } from '../types';
import { AuthActions } from './auth.actions';

export interface AuthState {
  user: UserType;
  error: unknown;
  authSpinner: boolean;
  redirectUrl: string;
}

export const initialAuthState: AuthState = {
  user: null,
  error: null,
  authSpinner: false,
  redirectUrl: null,
};

export const authReducer = createReducer(
  initialAuthState,
  on(AuthActions.loginSpinnerStart, (state) => ({ ...state, authSpinner: true })),
  on(AuthActions.loginSpinnerStopp, (state) => ({ ...state, authSpinner: false })),
  on(AuthActions.loadCurrentUserSuccessful, (state, { user }) => ({ ...state, user, error: null })),
  on(AuthActions.loadCurrentUserFailed, (state, { error }) => ({ ...state, error, user: null })),
  on(AuthActions.resetAuthStateAndStorage, () => ({ ...initialAuthState })),
  on(AuthActions.setRedirectAfterLoginSuccessful, (state, { redirectUrl }) => ({ ...state, redirectUrl })),
  on(AuthActions.clearRedirectUrl, (state) => ({ ...state, redirectUrl: null })),
  on(AuthActions.resetAuthStateAndStorage, () => ({ ...initialAuthState })),

  on(AuthActions.updateLanguageOnCurrentUser, (state, { languageCode }) => ({
    ...state,
    user: { ...state.user, languageCode },
  })),
);
