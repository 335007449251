export const DEFAULT_LANGUAGE = 'sv';
export const ALL = 'Alla';
export const ALL_WITH_HYPHENS = '- Alla -';
export const INCLUDE_FINISHED_LABEL = 'Visa även klara';
export const INCLUDE_UNFINISHED_LABEL = 'Visa samtliga oavslutade';
export const INCLUDE_FINISHED_CLIENTS_LABEL = 'Visa även helt klara klienter';
export const INCLUDE_ARCHIVED_CLIENTS_LABEL = 'Visa även arkiverade';
export const INCLUDE_TASKS_WITH_END_DATE_LABEL = 'Visa även tjänster med slutdatum';
export const ONLY_SHOW_ROWS_WITH_CONTENT_LABEL = 'Visa endast rader med innehåll (Skattekontosaldot visas alltid)';
export const ONLY_SHOW_ACTIVE_TASKS_TEMPLATES_LABEL = 'Visa endast aktiva tjänster';
export const CLIENT_TYPE_LIMITED_COMPANY = 'LIMITED_COMPANY';
export const CLIENT_TYPE_SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP';
export const CLIENT_TYPE_PARTNERSHIP = 'PARTNERSHIP';
export const CLIENT_TYPE_NON_PROFIT_ASSOCIATION = 'NON_PROFIT_ASSOCIATION';
export const CLIENT_TYPE_PERSON = 'PERSON';
export const CLIENT_RESPONSIBLE = 'Klientansvarig';
export const LOGGED_IN_USER = 'Inloggad medarbetare';
export const SINGLE_EVENT = 'SINGLE_EVENT';
export const ROOT_TEAM_NAME = 'Huvudteam';
