<app-please-wait [hidden]="hidePleaseWait"></app-please-wait>
<p>Välj vad du vill importera</p>
<p-dropdown
  [(ngModel)]="selectedImportType"
  optionValue="value"
  [options]="importTypeSelectOptions"
  [autoOptionFocus]="false"
  [autoDisplayFirst]="false">
</p-dropdown>

<div class="mt-3 mb-5">
  <label class="btn btn-primary btn-file">
    Välj fil
    <input
      #filechooser
      type="file"
      style="display: none"
      (change)="changeListener(filechooser)"
      [accept]="selectedImportType === 'client' ? '.csv, .txt, text/csv' : '.csv, text/csv'" />
  </label>
  {{ filePath }}
</div>

<div *ngIf="isVisible && isClientImportSelected">
  <input type="checkbox" [(ngModel)]="overWriteExistingOrgNumbers" /> Skriv över befintliga klienter (baserat på
  organisationsnummer)<br />
  <input type="checkbox" [(ngModel)]="includeEmptyOrgNumbers" /> Inkludera även företag som saknar
  organisationsnummer<br />
  <ng-container *appHideIfCountries="'NL'">
    <input type="checkbox" [(ngModel)]="applyDefaulTemplates" /> Lägg till valda tjänster vid importen enligt fliken
    Uppdragsmallar (observera att importfilen måste innehålla räkenskapsår och organisationsnummer)<br />
    <br />
    <h5>Ange startdatum för tjänsterna:</h5>
    <app-calendar
      [(selectedDate)]="templateStartDate"
      defaultDate=""
      appendTo="body"
      styleClass="w-48"
      [disabled]="!applyDefaulTemplates"></app-calendar>
    <br /><br />
  </ng-container>
  <input type="checkbox" [(ngModel)]="applyClientResponsible" /> Sätt klientansvarig<br />
  <p-dropdown
    [disabled]="!applyClientResponsible"
    [options]="availableUsersWithoutAll$ | async"
    [(ngModel)]="selectedUserId"
    [style]="{ width: '200px' }"
    appendTo="body"></p-dropdown
  ><br /><br />
  <button
    type="button"
    class="btn btn-success"
    [disabled]="!filePath || !hidePleaseWait"
    (click)="startImport(filechooser)">
    Importera</button
  ><br /><br />
  <div *ngIf="ignoredImports.length">
    <p class="font-semibold">Ignorerade importrader</p>
    <app-table
      [columns]="ignoredImportsColumns"
      [data]="ignoredImports"
      [searchField]="false"
      [columnSelector]="false"
      [paginator]="false">
      <ng-template tableTemplate="summary" let-data> {{ data.length }} klienter har inte importeras </ng-template>
    </app-table>
  </div>
</div>

<div *ngIf="isVisible && isDeferalImportSelected">
  <input type="checkbox" [(ngModel)]="clearPreviousDeferals" /> Avmarkera samtliga byråanståndsmarkeringar för aktuellt
  år innan import<br />
  <br /><br />
  <button
    type="button"
    class="btn btn-success"
    [disabled]="!filePath || !hidePleaseWait"
    (click)="startImport(filechooser)">
    Importera</button
  ><br /><br />
</div>
