import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { createErrorHandler } from '@sentry/angular-ivy';

import { DialogService } from 'primeng/dynamicdialog';
import { environment } from 'src/environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, INIT, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { UserService } from './core/services/user.service';

import { routing } from './app.routing';
import { BystSharedModule } from './shared/byst-shared.module';
import { AppComponent } from './app.component';
import { StartIframeComponent } from './start/start-iframe.component';
import { ActivitiesListComponent } from './activity/activities.list.component';
import { AccountingListComponent } from './accounting/accounting.list.component';
import { ClosingListComponent } from './closing/closing.list.component';
import { TodoListComponent } from './todo/todo.list.component';
import { IncomeStatementListComponent } from './incomestatement/incomestatement.list.component';
import { SalaryListComponent } from './salary/salary.list.component';
import { TaxDeclarationListComponent } from './taxdeclaration/taxdeclaration.list.component';
import { IncomeTaxDeclarationListComponent } from './incometaxdeclaration/incometaxdeclaration.list.component';
import { AdvisoryToolModule } from './advisory-tool/advisory-tool.module';
import { ImportMissingCompaniesToBystComponent } from './client/compare-cloud-companies/components/import-missing-companies-to-byst.component';
import { ExportCompaniesToCloudComponent } from './client/compare-cloud-companies/components/export-companies-to-cloud.component';
import { CompareCloudCompaniesDialogComponent } from './client/compare-cloud-companies/compare-cloud-companies-dialog.component';
import { SyncCompaniesBetweenBystCloudComponent } from './client/compare-cloud-companies/components/sync-companies-between-byst-cloud.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { MainAppComponent } from './start/main-app.component';
import { LoginComponent } from './login/login.component';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { SilentAuthComponent } from './login/silent-auth.component';
import { FirmSettingsModule } from './firm-settings/firm-settings.module';
import { AuthEffects } from './core/state/auth/auth.effects';
import { ToastEffects } from './core/state/toast/toast.effects';
import { UserEffects } from './core/state/users/users.effects';
import { usersReducer } from './core/state/users/users.reducers';
import { authReducer } from './core/state/auth/auth.reducers';
import { assignmentsReducer } from './core/state/assignments/assignments.reducers';
import { AppState } from './core/state/appState';
import { AssignmentEffects } from './core/state/assignments/assignments.effect';
import { clientsReducer } from './core/state/clients/clients.reducers';
import { ClientEffects } from './core/state/clients/clients.effect';
import { TodoEffects } from './core/state/todo/todo.effect';
import { CoreEffects } from './core/state/core/core.effects';
import { firmReducer } from './core/state/firm/firm.reducers';
import { FirmEffects } from './core/state/firm/firm.effects';
import { activitiesReducer } from './core/state/activities/activities.reducers';
import { ActivityEffects } from './core/state/activities/activities.effect';
import { ArchiveClientDialog } from './client/archive-client-dialog/archive-client-dialog.component';
import { ClientListComponent } from './client/client.list.component';
import { listsReducer } from './core/state/list/list.reducers';
import { fileVaultReducer } from './core/state/filevault/filevault.reducers';
import { FileVaultEffects } from './core/state/filevault/filevault.effect';
import { navigationReducer } from './core/state/navigation/navigation.reducers';
import { ClientTypeEffects } from './core/state/clientTypes/clientTypes.effect';
import { clientTypesReducer } from './core/state/clientTypes/clientTypes.reducers';
import { teamsReducer } from './core/state/teams/teams.reducers';
import { TeamsEffects } from './core/state/teams/teams.effects';
import { teamReducer } from './core/state/team/team.reducers';
import { TeamEffects } from './core/state/team/team.effects';
import { TodosEffects } from './core/state/todos/todos.effects';
import { TaskTemplateEffects } from './core/state/task-templates/task-templates.effects';
import { taskTemplateFeature } from './core/state/task-templates/task-templates.reducers';
import { ruleFeature } from './core/state/rule/rule.reducer';
import { RuleEffects } from './core/state/rule/rule.effects';
import { PeriodService } from './core/services/period.service';
import { CompositeEffects } from './core/state/composite/composite.effects';
import { TaskEffects } from './core/state/tasks/task.effects';
import { exportWizardFeature } from './core/state/export-wizard/export-wizard.feature';
import { ExportWizardEffects } from './core/state/export-wizard/export-wizard.effects';
import { TaskGroupsEffects } from './core/state/task-groups/task-groups.effects';
import { taskGroupsFeature } from './core/state/task-groups/task-groups.reducer';
import { TaskGroupEffects } from './core/state/task-group/task-group.effects';
import { AuthActions } from './core/state/auth/auth.actions';
import { clientTasksFeature } from './core/state/clientTasks/clientTasks.reducer';
import { ClientTasksEffects } from './core/state/clientTasks/clientTasks.effects';
import { featuresFeature } from './core/state/feature/feature.reducer';
import { MessagingService } from './core/services/messaging.service';
import { MessagingEffects } from './core/state/messaging/messaging.effects';
import { CreditInformationEffects } from './core/state/credit-information/credit-information.effects';
import { creditInformationFeature } from './core/state/credit-information/credit-information.reducer';
import { CollaborationPackageEffects } from './core/state/collaboration-packages/collaboration-packages.effects';
import { collaborationPackageItemReducer } from './core/state/collaboration-packages/collaboration-packages.reducers';
import { PartnerProgramModule } from './partnerprogram/partnerprogram.module';
import { PartnerProgramEffects } from './core/state/partnerprogram/partnerprogram.effects';
import { partnerProgramFeature } from './core/state/partnerprogram/partnerprogram.reducer';
import { BlaAuthComponent } from './login/bla-auth.component';
import { NewClientGuideDialogComponent } from './client/new-client-guide-dialog/new-client-guide-dialog.component';
import '@angular/common/locales/global/sv';
import { firmCollaboratorFeature } from './core/state/firm-collaborators/firm-collaborators.reducer';
import { FirmCollaboratorEffects } from './core/state/firm-collaborators/firm-collaborators.effect';
import { HideIfCountriesDirective } from './shared/directives/hide-if-countries.directive';
import { TranslocoRootModule } from './transloco-root.module';
import { TranslocoService } from '@jsverse/transloco';
import { DEFAULT_LANGUAGE } from './shared/misc/constants';
import { firstValueFrom } from 'rxjs';
import { NavbarComponent } from './shared/components/navbar/navbar.component';

export function errorHandlerFactory() {
  if (environment.production) {
    // return Sentry.createErrorHandler({ showDialog: false, logErrors: true });
    return createErrorHandler({ showDialog: false, logErrors: true }); // new SentryErrorHandler();
  }
  return new ErrorHandler();
}

export function getUserLang(transloco: TranslocoService) {
  return function ret() {
    // const lang = new UserSettingsStorage().loadSetting(UserSettingsStorage.LANGUAGE, DEFAULT_LANGUAGE);
    transloco.setActiveLang(DEFAULT_LANGUAGE);
    return firstValueFrom(transloco.load(DEFAULT_LANGUAGE));
  };
}

type RootReducer = { [P in keyof AppState]: ActionReducer<any, any> };
const reducers: RootReducer = {
  activities: activitiesReducer,
  assignments: assignmentsReducer,
  auth: authReducer,
  firm: firmReducer,
  clients: clientsReducer,
  users: usersReducer,
  list: listsReducer,
  fileVault: fileVaultReducer,
  navigation: navigationReducer,
  clientTypes: clientTypesReducer,
  taskTemplates: taskTemplateFeature.reducer,
  rules: ruleFeature.reducer,
  exportWizard: exportWizardFeature.reducer,
  taskGroups: taskGroupsFeature.reducer,
  teams: teamsReducer,
  team: teamReducer,
  clientTasks: clientTasksFeature.reducer,
  features: featuresFeature.reducer,
  creditInformation: creditInformationFeature.reducer,
  collaborationPackageItem: collaborationPackageItemReducer,
  partnerProgram: partnerProgramFeature.reducer,
  firmCollaborators: firmCollaboratorFeature.reducer,
};

// clear state when user logout
const metaReduser =
  (reducer: ActionReducer<any>): ActionReducer<any> =>
  (state, action) => {
    if (action.type === AuthActions.resetAuthStateAndStorage.type) {
      return reducer(undefined, { type: INIT });
    }
    return reducer(state, action);
  };

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslocoRootModule,
    routing,
    BystSharedModule,
    AdvisoryToolModule,
    FirmSettingsModule,
    PartnerProgramModule,
    NewClientGuideDialogComponent,
    NavbarComponent,
    HideIfCountriesDirective,
    StoreModule.forRoot(reducers, { metaReducers: [metaReduser] }),
    StoreDevtoolsModule.instrument({ name: 'Byråstöd', maxAge: 500, connectInZone: true }),
    EffectsModule.forRoot([
      AuthEffects,
      CoreEffects,
      ToastEffects,
      UserEffects,
      AssignmentEffects,
      ClientEffects,
      TodoEffects,
      FirmEffects,
      ActivityEffects,
      FileVaultEffects,
      ClientTypeEffects,
      TeamsEffects,
      TeamEffects,
      TodosEffects,
      TaskTemplateEffects,
      RuleEffects,
      CompositeEffects,
      TaskEffects,
      ExportWizardEffects,
      TaskGroupsEffects,
      TaskGroupEffects,
      ClientTasksEffects,
      MessagingEffects,
      CreditInformationEffects,
      CollaborationPackageEffects,
      PartnerProgramEffects,
      FirmCollaboratorEffects,
    ]),
  ],
  declarations: [
    AppComponent,
    MainAppComponent,
    LoginComponent,
    SilentAuthComponent,
    BlaAuthComponent,
    CompareCloudCompaniesDialogComponent,
    ImportMissingCompaniesToBystComponent,
    ExportCompaniesToCloudComponent,
    SyncCompaniesBetweenBystCloudComponent,
    ActivitiesListComponent,
    AccountingListComponent,
    IncomeStatementListComponent,
    ClosingListComponent,
    SalaryListComponent,
    IncomeTaxDeclarationListComponent,
    TaxDeclarationListComponent,
    TodoListComponent,
    StartIframeComponent,
    NotificationsComponent,
    ArchiveClientDialog,
    ClientListComponent,
  ],
  providers: [
    UserService,
    PeriodService,
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'sv' },
    { provide: APP_INITIALIZER, multi: true, deps: [TranslocoService], useFactory: getUserLang },
    DialogService,
    MessagingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
