<div class="grid gap-8 grid-cols-5">
  <div class="col-auto">
    <app-team-root-team (teamClick)="onTeamClick($event)"> </app-team-root-team>
    <app-team-warnings></app-team-warnings>
  </div>
  <div class="col-span-4 border-l border-bl-grey-200 px-8">
    <p class="font-semibold text-lg mt-8 mb-1">Klientbaserade team</p>
    <p class="mb-7 text-bl-grey-600">Medarbetare i teamen får endast tillgång till valda klienter.</p>
    <app-teams-grid
      [teamType]="clientTeamType"
      (teamClick)="onTeamClick($event)"
      (newTeamClick)="onNewClientTeamClick()"></app-teams-grid>

    <ng-container *appHideIfCountries="'NL'">
      <p class="font-semibold text-lg mt-10 mb-1">Tjänstebaserade team</p>
      <p class="mb-7 text-bl-grey-600">Medarbetare i teamen får endast tillgång till klienter med valda tjänster.</p>
      <app-teams-grid
        [teamType]="taskTeamType"
        (teamClick)="onTeamClick($event)"
        (newTeamClick)="onNewTaskTeamClick()"></app-teams-grid>
    </ng-container>
  </div>
</div>

<app-add-team-dialog></app-add-team-dialog>
<app-edit-team-dialog></app-edit-team-dialog>
